import {
  createCart,
  getCart,
  clearCart,
  deleteItemFromCart,
  removeItemsAndSetRecipes,
  updateItem,
  removeRecipe,
  removeAllRecipes,
  createReplacement,
  applyDiscount,
  removeDiscount,
  resolveDiscountGtin,
  moveCartToNewStoreId,
  deleteUsableCarts as deleteUsableCartsApiCall,
  refreshCartCalculations
} from '../../api/endpoints';
import {
  requestCart,
  receiveCart,
  syncCart,
  replaceCart,
  clearCart as clearCartSync,
  setReplacementMode,
  discountApplying,
  discountSuccess,
  discountFailed,
  deleteUsableCarts,
  updatePickingFee,
  discountReset
} from './sync';
import { syncItems } from '../order';
import { getPickingFees } from '../../lib/cart';
import Cookies from 'universal-cookie';
import { ga4CouponInput } from '../../lib/analytics/analytics';
import { COUPON_ERRORS } from '../../components/CartList/CartCoupons/CartCoupons';
import { setCartToChange } from '../newCartPrep';

const handleResp = (dispatch, fn = syncCart) => cart => dispatch(fn(cart));

const handleError = err => console.error(err);
/* Products */

export const handleStoreIdChange = (
  cartId,
  storeId,
  deliveryPickerCart
) => dispatch => {
  return moveCartToNewStoreId(cartId, storeId).then(({ data }) => {
    if (deliveryPickerCart) {
      dispatch(setCartToChange([data.id, cartId]));
    }

    dispatch(receiveCart(data));
    syncItems(dispatch)(data);
    return data;
  });
};

export const createCartAsync = storeNumber => dispatch => {
  dispatch(requestCart());
  return createCart(storeNumber)
    .then(({ data }) => {
      dispatch(receiveCart(data));
      return data;
    })
    .catch(err => {
      return handleError(err);
    });
};

export const getCartAsync = id => dispatch =>
  getCart(id)
    .then(({ data }) => {
      handleResp(dispatch, receiveCart)(data);
    })
    .catch(handleError);

export const updateItemAsync = (id, productId, quantity) => dispatch =>
  updateItem(id, productId, quantity).then(({ data }) =>
    handleResp(dispatch)(data)
  );

export const deleteFromCartAsync = (id, productId) => dispatch =>
  deleteItemFromCart(id, productId).then(({ data }) => {
    handleResp(dispatch)(data);
    return data;
  });

export const deleteItemsFromCartAsync = (id, productIds) => dispatch => {
  return removeItemsAndSetRecipes(id, productIds)
    .then(cart => {
      // selects last element in res
      handleResp(dispatch)(cart);
    })
    .catch(err => {
      handleError(err);
    });
};

export const clearCartAsync = id => dispatch =>
  clearCart(id)
    .then(({ data }) => data)
    .catch(handleError);

export const deleteUsableCartsAsync = () => dispatch =>
  deleteUsableCartsApiCall().then(() => dispatch(deleteUsableCarts()));

/* Recipes */
export const deleteRecipeFromCartAsync = (cartId, cartItemId) => dispatch =>
  removeRecipe(cartId, cartItemId)
    .then(({ data }) => {
      handleResp(dispatch)(data);
      return data;
    })
    .catch(handleError);

export const clearRecipesAsync = id => dispatch =>
  removeAllRecipes(id)
    .then(({ data }) => dispatch(syncCart(data)))
    .catch(handleError);

export const createReplacementCart = (orderId, storeNo) => dispatch => {
  return createReplacement(orderId, storeNo)
    .then(({ data }) => syncItems(dispatch)(data))
    .then(cart => {
      dispatch(replaceCart(cart));
      dispatch(setReplacementMode(true));

      dispatch(receiveCart(cart));

      return cart;
    });
};

export const clearCartGetNext = (currentCart, nextCart) => dispatch => {
  dispatch(setReplacementMode(false));
  // ta bort cart
  dispatch(clearCartSync(currentCart.id));
  dispatch(discountReset());
  const fetchingNext = nextCart
    ? getCart(nextCart.id)
        .then(({ data }) => syncItems(dispatch)(data))
        .then(cart => {
          if (!cart.deletedDate) {
            if (cart.replacementForOrderId) {
              dispatch(setReplacementMode(true));
            }
            dispatch(receiveCart(cart));
          }
        })
    : Promise.resolve(null);

  return fetchingNext;
};

export const applyDiscountCode = (
  cartId,
  code,
  storeProvider
) => async dispatch => {
  dispatch(discountApplying());
  // TODO: Denna borde returnera med en 404 om den inte hittas...
  // Just nu ger api:et 200-OK & null

  // if code is 13-14 numbers and does not include letters
  // then we can assume that it is a gtin

  let gtin = null;
  let relatedGtin = null;
  let name = null;
  const gtinRegex = /^[0-9]{13,14}$/g;
  const isGtin = gtinRegex.test(code);

  const response = await resolveDiscountGtin(code);

  if (response.data && response.data.gtin) {
    const gtinArray = response.data.gtin.split('|');
    if (storeProvider === 'Axfood') {
      gtin = gtinArray[0];
      relatedGtin = gtinArray[1];
    } else {
      gtin = gtinArray[1];
      relatedGtin = gtinArray[0];
    }
    name = response.data?.code;
  } else if (!response.data && isGtin && storeProvider !== 'Axfood') {
    gtin = code;
  } else if (storeProvider === 'Axfood') {
    // is axfood
    name = code;
  } else {
    // assume its an invalid code
    dispatch(discountFailed({ code: COUPON_ERRORS.ERR_INVALID_COUPON }));
    return;
  }

  applyDiscount(cartId, gtin, name, relatedGtin)
    .then(cart => {
      dispatch(syncCart(cart.data));
      dispatch(discountSuccess());
      ga4CouponInput(code);
    })
    .catch(err => {
      const errorMessage = err.response?.data?.errors?.[0];
      if (errorMessage?.code) {
        dispatch(
          discountFailed({
            code: errorMessage?.code,
            message: errorMessage?.message
          })
        );
      } else {
        dispatch(discountFailed({ code: COUPON_ERRORS.ERR_COUPON_VALIDATION }));
      }
    });
};

export const retriveDiscountName = code => dispatch =>
  resolveDiscountGtin(code);

export const removeDiscountCode = (cartId, code) => dispatch => {
  removeDiscount(cartId, code);
};

export const refreshCalculations = (cartId, orderId) => dispatch => {
  refreshCartCalculations(cartId, null).then(cart => {
    dispatch(syncCart(cart.data));
  });
};

export const setPickingFeeRange = (cartState, callback = null) => dispatch => {
  const cookieStorage = new Cookies();
  const assortmentCookie = cookieStorage.get('assortment_values');

  getPickingFees(
    assortmentCookie?.deliveryType,
    assortmentCookie?.userPostalCode,
    cartState.id,
    assortmentCookie?.userSiteId,
    cartState.isReplacement
  )
    .then(pickingFees => {
      if (cartState?.lowestPickingFee !== pickingFees?.lowestPickingFee)
        dispatch(
          updatePickingFee(
            pickingFees.lowestPickingFee,
            pickingFees.highestPickingFee,
            pickingFees.lowestPickingFeeLimit,
            pickingFees.originalPickingFee
          )
        );

      if (callback) callback();
    })
    .catch(error => {
      handleError(error);
      dispatch(updatePickingFee(49, null));
      if (callback) callback();
    });
};

export const setPickingFee = pickingFee => dispatch => {
  dispatch(updatePickingFee(pickingFee));
};
