import React, { Component } from 'react';
import ShrinkedOpacityText from '../../components/ShrinkedOpacityText';
import ProductSingleDetails from './ProductSingleDetails';
import './ProductSingle.scss';
import RichText from '../../containers/RichText';
import Image from '../../components/Image';
import { getElementScrollContainer } from '../../lib/scroll';
import {
  RecommendedProducts,
  RelatedProducts
} from '../../components/ProductPanels';
import {
  ticketNotifierByClick,
  ticketNotifierByAddingToCard
} from '../../api/endpoints/notify';
import { LinkablePageTitle as PageTitle } from '../../components/PageTitle';
import { InformationText } from '../../constants/InformationText';
import * as ga4 from '@citygross/analytics';
import { formatPrice } from './../../store/middleware/analytics';
import { capitalizeWord } from '../../lib/utils';
import { Link as CgLink } from '@citygross/components';

export default class ProductSingle extends Component {
  constructor(props) {
    super(props);

    this.rootElementRef = React.createRef();
  }

  componentDidMount() {
    this.scrollToTop();
    try {
      const { gtin, name, url, brand, price } = this.props.product;

      const ga4Item = {
        value: price?.currentPrice.price || 0,
        currency: 'SEK',
        items: [
          {
            item_id: gtin,
            item_name: name,
            item_brand: brand || 'City Gross',
            item_category:
              url?.substring(0, url.lastIndexOf('/') + 1) || undefined,
            item_variant: this.props.product.variant || undefined,

            price: price.ordinaryPrice.price || 0,
            quantity: 1,
            discount: 0
          }
        ]
      };

      if (price.hasPromotion && price.promotion) {
        ga4Item.value = price.promotion.value;
        ga4Item.items[0].quantity = price.promotion.minQuantity;
        ga4Item.items[0].discount = Number(
          formatPrice(
            price.promotion.minQuantity * price.ordinaryPrice.price -
              price.promotion.value
          )
        );
      }

      ga4.viewItemDetails(ga4Item);
    } catch (error) {
      console.error(error);
    }
  }

  componentDidUpdate(previousProps) {
    if (this.props.product.id !== previousProps.product.id) {
      this.scrollToTop();
    }
  }

  scrollToTop() {
    const currentRootElement = this.rootElementRef.current;
    const scrollContainerElement = getElementScrollContainer(
      currentRootElement
    );
    if (scrollContainerElement) {
      // Workaround for IE11
      if (scrollContainerElement.scrollTo) {
        scrollContainerElement.scrollTo(0, 0);
      } else {
        scrollContainerElement.scrollTop = 0;
      }
    }
  }

  render() {
    const {
      currentBreadCrumb,
      product,
      isAuthenticated,
      storeId,
      isFlyout
    } = this.props;
    const {
      description,
      ingredientStatement,
      markings,
      storageInformation,
      guaranteedDurability,
      precautionaryStatement,
      handling,
      foodAndBeverageExtension,
      mappedSafetyDataSheetInformation,
      superCategory,
      countryOfOrigin,
      uniformResourceIdentifier,
      sortCountryInfo,
      countryOfManufacture
    } = product;
    const hasTexts =
      description ||
      ingredientStatement ||
      (markings && markings.length > 0) ||
      storageInformation ||
      guaranteedDurability ||
      precautionaryStatement ||
      (handling && handling.minimumLifespanFromTimeOfArrivalInDays) ||
      (foodAndBeverageExtension &&
        (foodAndBeverageExtension.ingredientStatement ||
          foodAndBeverageExtension.nutrientInformations)) ||
      mappedSafetyDataSheetInformation;

    const isTobaccoProduct = superCategory === 'Tobak';
    const sortCountries = sortCountryInfo?.filter(country => {
      const validFrom = new Date(country.validFrom) < new Date();
      const validTo = new Date(country.validTo) > new Date();
      return validFrom && validTo && country.mainCountry !== 'X';
    });

    return (
      <div className="c-productdetails" ref={this.rootElementRef}>
        {currentBreadCrumb && <PageTitle currentPage={currentBreadCrumb} />}
        <ProductSingleDetails
          product={product}
          isAuthenticated={isAuthenticated}
          esalesClickTrigger={
            product.ticket
              ? () => ticketNotifierByClick(product.ticket)
              : () => {}
          }
          esalesClickTriggerOnAddingToCart={
            product.ticket
              ? () => ticketNotifierByAddingToCard(product.ticket)
              : () => {}
          }
        />
        {hasTexts && (
          <div className="c-productdetails__texts">
            <RichText>
              <ShrinkedOpacityText>
                {description && (
                  <div style={{ marginBottom: '10px' }}>
                    <h3>Beskrivning</h3>
                    <div>
                      <RichText text={description} />
                    </div>
                    {uniformResourceIdentifier &&
                      uniformResourceIdentifier.indexOf('www.fass.se') > 0 && (
                        <div style={{ marginTop: '10px' }}>
                          Läs mer på{' '}
                          <CgLink
                            hrefPath={uniformResourceIdentifier}
                            target="_blank"
                          >
                            fass
                          </CgLink>
                        </div>
                      )}
                  </div>
                )}
                {sortCountries?.length && (
                  <div style={{ marginBottom: '10px' }}>
                    <h3>Möjliga länder vid ersättningar</h3>
                    <div>
                      {sortCountries?.map((country, i, row) => {
                        return i + 1 === row.length
                          ? country.countryDesc
                          : country.countryDesc + ', ';
                      })}
                    </div>
                  </div>
                )}
                {storageInformation && (
                  <div style={{ marginBottom: '10px' }}>
                    <h3>Förvaring</h3>
                    <div>
                      <RichText text={storageInformation} />
                    </div>
                  </div>
                )}
                {handling && handling.minimumLifespanFromTimeOfArrivalInDays && (
                  <div style={{ marginBottom: '10px' }}>
                    <h3>Garanterad hållbarhet</h3>
                    <p>
                      Minst {handling.minimumLifespanFromTimeOfArrivalInDays}{' '}
                      {handling.minimumLifespanFromTimeOfArrivalInDays === 1
                        ? 'dag'
                        : 'dagar'}
                      {' ('}
                      vårt genomsnitt{' '}
                      {handling.averageLifespanFromTimeOfArrivalInDays} dagar
                      {')'}
                    </p>
                  </div>
                )}
                {ingredientStatement && (
                  <div style={{ marginBottom: '10px' }}>
                    <h3>Ingredienser</h3>
                    <div>
                      <RichText text={ingredientStatement} />
                    </div>
                  </div>
                )}
                {product.mappedMarkings?.length > 0 && (
                  <div style={{ marginBottom: '10px' }}>
                    <h3>Märkningar</h3>
                    <p>
                      {product.mappedMarkings.map(({ name, code }, index) => (
                        <span key={code}>
                          {name || code}
                          {markings?.length > index + 1 ? ',' : ''}{' '}
                        </span>
                      ))}
                    </p>
                  </div>
                )}
                {foodAndBeverageExtension &&
                  foodAndBeverageExtension.nutrientInformations &&
                  foodAndBeverageExtension.nutrientInformations?.[0]
                    ?.nutrientStatement && (
                    <div style={{ marginBottom: '10px' }}>
                      <h3>Näringsinnehåll</h3>
                      <div>
                        <RichText
                          text={
                            foodAndBeverageExtension.nutrientInformations[0]
                              .nutrientStatement
                          }
                        />
                      </div>
                    </div>
                  )}
                {foodAndBeverageExtension &&
                  foodAndBeverageExtension.ingredientStatement && (
                    <div style={{ marginBottom: '10px' }}>
                      <h3>Ingredienser</h3>
                      <div>
                        <RichText
                          text={foodAndBeverageExtension.ingredientStatement}
                        />
                      </div>
                    </div>
                  )}
                {precautionaryStatement && (
                  <div style={{ marginBottom: '10px' }}>
                    <h3>Varningar</h3>
                    <div>
                      <RichText text={precautionaryStatement} />
                    </div>
                  </div>
                )}
                {mappedSafetyDataSheetInformation && (
                  <div className="c-productdetails__hazardcontainer">
                    {mappedSafetyDataSheetInformation.highlightedInformation && (
                      <div className="c-productdetails__hazard">
                        {mappedSafetyDataSheetInformation.highlightedInformation
                          .filter(
                            x =>
                              x.qualifier ===
                              'http://www.unece.org/GHS/SymbolDescription'
                          )
                          .map(({ image, altText, code }) => (
                            <div
                              className="c-productdetails__hazardicon"
                              key={code}
                            >
                              {image && (
                                <Image
                                  src={image.url}
                                  alt={altText}
                                  width={25}
                                  height={25}
                                  noLazy
                                  style={{
                                    display: 'block'
                                  }}
                                />
                              )}
                            </div>
                          ))}
                        {mappedSafetyDataSheetInformation.highlightedInformation &&
                          mappedSafetyDataSheetInformation.highlightedInformation
                            .filter(
                              x =>
                                x.qualifier ===
                                'http://www.unece.org/GHS/SignalWord'
                            )
                            .map(({ description, code }) => (
                              <span
                                key={code}
                                className="c-productdetails__signalword"
                              >
                                {description}
                              </span>
                            ))}
                      </div>
                    )}
                    <h3 style={{ marginTop: '10px' }}>Skyddsangivelse</h3>
                    {mappedSafetyDataSheetInformation.hazardCodes.map(
                      ({ code, description }) => (
                        <div className="c-productdetails__hazard" key={code}>
                          <span>{description}</span>
                        </div>
                      )
                    )}
                  </div>
                )}
                {countryOfOrigin && (
                  <div style={{ marginBottom: '10px' }}>
                    <h3>Ursprungsland</h3>
                    <div>{capitalizeWord(countryOfOrigin)}</div>
                  </div>
                )}
                {countryOfManufacture && (
                  <div style={{ marginBottom: '10px' }}>
                    <h3>Tillverkningsland</h3>
                    <div>{capitalizeWord(countryOfManufacture)}</div>
                  </div>
                )}
                <div style={{ marginBottom: '10px' }}>
                  <h3>Bra att veta</h3>
                  <div>
                    <RichText text={InformationText} />
                  </div>
                </div>
              </ShrinkedOpacityText>
            </RichText>
          </div>
        )}
        {!isTobaccoProduct && (
          <RelatedProducts
            key={this.props.product.id}
            categoryCode={this.props.product.bfCategoryCode}
            bfabId={this.props.product.id}
            storeId={storeId}
          />
        )}
        {!isTobaccoProduct && (
          <RecommendedProducts
            productId={this.props.product.id}
            excludeCategoryByCode={this.props.product.bfCategoryCode}
            storeId={storeId}
            isFlyout={isFlyout}
          />
        )}
      </div>
    );
  }
}
